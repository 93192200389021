import React, { useEffect, useState } from 'react';
import './App.css';
import './constants/firebase'

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login'
import CircularProgress from '@mui/material/CircularProgress';

import { onAuthStateChanged, getAuth } from "firebase/auth";


function App() {

  const auth = getAuth();
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (userCredential) => {
      // check if user is already logged in
      if (userCredential) {
        setUser(userCredential);
      } else {
        setUser(null);
      }
      setLoading(false)
    });
  }, []);


  if (loading) {
    return(
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh"}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="container">
      
      {
        user ?
        <Home user={user} />
          :
         <Login />
      }
    </div>
  );
}

export default App;
