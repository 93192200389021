// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJiR8NfJPjf9KspMbdtEQtPRCEq1iHCGQ",
  authDomain: "mind-1f225.firebaseapp.com",
  databaseURL: "https://mind-1f225-default-rtdb.firebaseio.com",
  projectId: "mind-1f225",
  storageBucket: "mind-1f225.appspot.com",
  messagingSenderId: "961179552736",
  appId: "1:961179552736:web:b8c1dd967feb802f0fb985",
  measurementId: "G-N0KLP10PW8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);