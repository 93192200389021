import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { signOut, getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import Navbar from '../components/Navbar';

const messaging = getMessaging();

let api = 'https://testing.matthewgruman.com/';
//api = 'http://localhost:8082/'

function App(props) {
  const { uid, accessToken } = props.user;
  console.log(props.user)

  const auth = getAuth();
  const [isActive, setIsActive] = useState(false)
  const [reminders, setReminders] = useState('');
  const [email, setEmail] = useState('');
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [saved, setSaved] = useState(false);

  function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
    })
  }

  function sendNotification() {
    // Add the public key generated from the console here.
getToken(messaging, {vapidKey: "BFpS88fSmzU34KZTPbbpUJY3SNaF_32ZLgyXinM0okqALdpQCvqV6RVwOfwxhlZxEuWqPVT4Gc72IK9dA9sAExg"})
.then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log(currentToken)
    axios.post('http://localhost:8082/private/sendNotification', {
      token: currentToken
    })
    .then (results => {
      console.log(results.data);
    })
    .catch(err => {
      console.log(err);
    })
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});
  }
  useEffect(() => {
    props.user &&
      axios.post("https://testing.matthewgruman.com/public/getItems", {
        path: "users/" + props.user.uid,
      }).then(results => {
        setIsActive(results.data[0])
    //    setBody(results.data[1]);
        setEmail(results.data[2])
        setEnd(dayjs(results.data[3]))
        setReminders(results.data[4])
        setStart(dayjs(results.data[5]))
        console.log(results.data[3]);
      })
        .catch(err => {
          console.log(err);
        })

  }, [props.user])

  function save() {
    if (!reminders) {
      alert("You must set at least one reminder");
      return;
    }
    if (!start) {
      alert("You must set a start hour");
      return;
    }
    if (!end) {
      alert("You must set an end hour");
      return;
    }
    if (!email) {
      alert("You must set an email");
      return;
    }

    axios.post(
      api + "public/updateItem",
      {
        path: "users/" + uid,
        item: {
          reminders: reminders,
          startTime: start.$d,
          endTime: end.$d,
          email: email,
          updated: Date.now(),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((results) => {
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });

  }


  return (
    <>
    <Navbar user={props.user} />
    {/* <Button onClick={() => sendNotification()}>Send text</Button> */}
      <h3>Write your reminders one line at a time:</h3>
      <textarea value={reminders} onChange={(e) => setReminders(e.target.value)} />
      <h3>Your email:</h3>
      <TextField sx={{ mb: 2 }} style={{ width: "100%" }} type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <h3>Start and end times:</h3>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker sx={{ mb: 2 }} label="Start time"
          value={start}
          onChange={(newValue) => setStart(newValue)} />
        <TimePicker sx={{ mb: 2 }} label="End time"
          value={end}
          onChange={(newValue) => setEnd(newValue)} />
      </LocalizationProvider>
     
      {
        saved &&
        <p className="saved">Saved!</p>
      }
      <div className="save">
        <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
          <Button color="success" variant="contained" onClick={() => save()} fullWidth>Save</Button>
        </div><Button fullWidth onClick={() => signOut(auth)} >Sign Out</Button>

      </div>

    </>
  );
}

export default App;
