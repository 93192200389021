import React, { useEffect, useState } from 'react';
import { AccessAlarm, Email, ArrowForward, ArrowBack } from '@mui/icons-material';

import Button from '@mui/material/Button';
import {
  GoogleAuthProvider, getAuth, signInWithPopup, FacebookAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from "firebase/auth";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import GoogleIcon from '@mui/icons-material/Google';

function App() {
  const provider = new GoogleAuthProvider();
  const fbProvider = new FacebookAuthProvider();
  const auth = getAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newErrorMessage, setNewErrorMessage] = useState("");

  function handleCreate(e) {
    e.preventDefault();
    setErrorMessage("");
    setNewErrorMessage("");
    if (newEmail && newPassword) {
      createUserWithEmailAndPassword(auth, newEmail, newPassword)
        .then((userCredential) => {
          // Signed in
          console.log(userCredential)
        })
        .catch((error) => {
          //setErrorMessage(error.message);
          console.log(error.message);
        });
    } else {
      setNewErrorMessage("Please fill out both fields.");
    }
  }

  function handleLogin(e) {
    e.preventDefault();
    setErrorMessage("");
    setNewErrorMessage("");
    if (email && password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
      })
      .catch((error) => {
        console.log(error.message);
      });
    } else {
      setNewErrorMessage("Please fill out both fields.");
    }
  }
  function signInGoogle() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)
        // ...
      });
  }

  function signInFacebook() {
    signInWithPopup(auth, fbProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });
  }

  return (

    <div className="login">
      <h1>Gruminders</h1>
      <h4>Daily reminders emailed at random<br /> between times of your choosing.</h4>
      <div className="center">
      <Button color="error" startIcon={<GoogleIcon style={{color: '#fff'}} />} sx={{ mb: 4, mt: 1 }} variant="contained" onClick={() => signInGoogle()}>Sign in with Google</Button>
      </div>
      <h2 style={{marginBottom: "1rem"}}>Login by Email</h2>
      { errorMessage && <Alert  sx={{ mb: 2, mt: 2 }} severity="error">{errorMessage}</Alert> }
      <form onSubmit={(e) => handleLogin(e)}>
    <h3>Your email:</h3>
      <TextField sx={{ mb: 2 }} style={{ width: "100%" }} type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <h3>Your password:</h3>
      <TextField sx={{ mb: 2 }} style={{ width: "100%" }} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <Button color="success" sx={{ mb: 4, }} variant="contained" onClick={(e) => handleLogin(e)}>Submit</Button>
    </form>
      <h2 style={{marginBottom: "1rem"}}>Create Account</h2>
      { newErrorMessage && <Alert sx={{ mb: 2, mt: 2 }} severity="error">{newErrorMessage}</Alert> }
      <form onSubmit={(e) => handleCreate(e)}>
      <h3>Your email:</h3>
      <TextField sx={{ mb: 2 }} style={{ width: "100%" }} type="text" placeholder="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
      <h3>Your password:</h3>
      <TextField sx={{ mb: 2 }} style={{ width: "100%" }} type="password" placeholder="Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      <Button color="success" sx={{ mb: 2, }} variant="contained" onClick={(e) => handleCreate(e)}>Submit</Button>
      </form>
    </div>
  );
}

export default App;
