import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
  
function Header(props) {
  const [isActive, setIsActive] = useState(false)

    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const [info, setInfo] = useState(false);
  
    function signIn() {
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
          // ...
        });
    }
  useEffect(() => {
    if (props.user && props.user.uid) {
      axios.post("https://testing.matthewgruman.com/public/getItems", {
        path: "users/" + props.user.uid,
      }).then(results => {
        setIsActive(results.data[0])
        console.log(results.data)
      })
        .catch(err => {
          console.log(err);
        })
      }
  }, [props.user])

  function toggleActive() {
    setIsActive(old => !old)
    axios.post("https://testing.matthewgruman.com/public/updateItem",
      {
        path: "users/" + props.user.uid,
        item: {
          active: !isActive,
          updated: Date.now()
        }
      }).then(results => {
        console.log(results.data)
      }).catch(err => {
        console.log(err);
      })
      
  }

  return (
    <>
    <header style={{display: "flex", justifyContent: "space-between"}}>

<Button color="success" variant={info ? "outlined" : "contained"} onClick={() => setInfo(old => !old)}>Gruminders</Button>
      
        <Button color="success" variant={isActive ? "contained" : "outlined"} onClick={() => toggleActive()}>{isActive ? "Active" : "Inactive"}</Button>
      
    </header>
    {
          info &&
          <p>Info goes here</p>
}
</>
  );
}

export default Header;
